<template>
  <div class="error">404</div>
</template>

<script>
export default {
  name: 'error',
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
.error {
  font-size: 18px;
}
</style>
